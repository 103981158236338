import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseH4",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'h4'
        }
    },
}
